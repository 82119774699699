import React, { useState, useRef } from 'react';
import { Icon } from '~/components/common/Icon';
import { getTailwindColor } from '~/utils/style/tailwind';
import { HeatwaveGradient } from '~/components/common/HeatwaveGradient';

interface CollapsedProps {
  title: string;
  content: string;
}

const Collapse: React.FC<CollapsedProps> = ({ title, content }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  const handleToggle = () => {
    setCollapsed((collapsed) => !collapsed);
    setHeight(!collapsed ? '0px' : `${(contentRef.current as any)?.scrollHeight}px`);
  };

  return (
    <div
      className={`w-full sm:w-[38rem] lg:w-[58.813rem] my-12 px-5 lg:px-7 hover:cursor-pointer relative h-fit select-none`}
      onClick={handleToggle}
    >
      <div
        className={`transition-all ease-in-out duration-500 absolute h-[116%] w-full -top-[6%] left-0 ${
          collapsed ? 'opacity-0' : 'opacity-100'
        }`}
      >
        <HeatwaveGradient gradient={{ fromOpacity: 20, toOpacity: 10 }} rounded='rounded-2.5xl' />
      </div>

      <div className='flex items-start sm:items-center justify-between w-full'>
        <div className='text-white space-grotesk-regular font-medium text-xl lg:text-[1.8rem] leading-snug lg:leading-[42px] max-w-[80%] lg:max-w-full'>
          {title}
        </div>
        <div className='lg:-translate-x-[0.70rem] -translate-x-[0.125rem] lg:translate-y-0 flex items-start'>
          <Icon
            name={collapsed ? 'chevronDown' : 'chevronUp'}
            color={getTailwindColor('coldsnap4')}
            size='large'
          />
        </div>
      </div>
      <div
        ref={contentRef}
        style={{ height }}
        className='overflow-hidden transition-height duration-500 ease-in-out'
      >
        <div
          className={`text-white lg:text-night1 relative space-grotesk-regular text-lg lg:text-xl [text-shadow:_0_18px_33px_rgb(10_13_36_/_30%),_0_3px_10px_rgba(10_13_36_/_20%)] font-normal my-5 max-w-[92%] mt-[1rem] lg:mt-[1.25rem] transition-all ease-in-out duration-[250ms] h-full`}
        >
          {content}
        </div>
      </div>
    </div>
  );
};
export default Collapse;
