import Image from 'next/legacy/image';
import React from 'react';
import { VideoPlayer } from '~/components/common/VideoPlayer';

export interface TheGamesScrollDesktopProps {
  version?: string;
}

const dictionary = {
  default: {
    team: {
      title: `Team v Team Games`,
      desc: `Down for some friendly competition? Split up into teams and face-off in everything from wordy brain teasers to new twists on arcade classics.`,
    },
    competitive: {
      title: `Competitive Games`,
      desc: `Ramp up the rivalry and go solo as you battle it out with other players 1-on-1 to prove your gaming dominance.`,
    },
    icebreakers: {
      title: `Social Games`,
      desc: `Whether you want to get to know each other better or put your wits to the test, these games are all about raising the vibes and getting your entire crew cracking up in no time.
`,
    },
  },
  remote: {
    team: {
      title: `Team-building Games`,
      desc: `Nothing says bonding like frantically screaming at a computer screen together! These
            games allow you to divide your co-workers into teams and work together to solve
            everything from brain teasers to strategic head-scratchers.`,
    },
    competitive: {
      title: `Competitive Games`,
      desc: `Unleash your team's competitive side with head-to-head games that prioritize
            tearing the other team to shreds (all in good fun, of course 😉) over peaceful
            camaraderie.`,
    },
    icebreakers: {
      title: `Actually Fun Icebreakers`,
      desc: `Uncover a whole new level of bonding with icebreakers that break free from the boredom.
            Who knows? You might even discover that Judy, your quiet accountant, is secretly a black
            belt in karate!`,
    },
  },
};

const TheGamesScrollDesktop: React.FC<TheGamesScrollDesktopProps> = ({ version }) => {
  const optimizationQueryLower = 'g_center,c_fill,q_90,h_540,w_450';
  const optimizationQueryHigher = 'g_center,c_fill,q_90,h_600,w_450';
  const optimizationQueryHorizontal = 'g_center,c_fill,q_90,h_550,w_941';

  const previewImageOptimization = 'q_auto,f_auto,c_fill';

  const words = dictionary[version as keyof typeof dictionary] || dictionary.default;

  // ! NOTE !
  // ! Make sure to NOT include the FILE EXTENSION in the video name
  // ! Both columns need 4 videos

  const teamBuildingGames = [
    '/v1697207149/b2b-landing-page-august-23/wordy-waterway-1',
    '/v1697207131/b2b-landing-page-august-23/word-hunt-3',
  ];

  const competetiveGames = [
    '/v1697207099/b2b-landing-page-august-23/sumo-smash-2',
    '/v1697207081/b2b-landing-page-august-23/omega-pinch-highlight-2',
  ];

  const icebreakerGames = ['/v1697207061/b2b-landing-page-august-23/read-the-room-2'];

  return (
    <div className='relative h-full w-full flex gap-x-16 pl-[70px] pt-[10rem] pb-32 lg:pt-28 container max-w-[1520px]'>
      {/* scroll column */}
      <div className='absolute inset-x-0 inset-y-1/2 top-24 left-1/4 opacity-20'>
        <Image
          src='/assets/landingB2BAug23/theGames/the_games_gradient.webp'
          alt='gradient'
          layout='fill'
          loading='eager'
        />
      </div>

      <div className='absolute inset-x-0 inset-y-1/3 -bottom-64 left-1/4 -rotate-90 opacity-20'>
        <Image
          src='/assets/landingB2BAug23/theGames/the_games_gradient.webp'
          alt='gradient'
          layout='fill'
          loading='eager'
        />
      </div>

      <div className='w-[27rem] h-full mt-20'>
        <div className='space-y-3.5 h-[40rem] flex items-start justify-start flex-col ml-[1rem]'>
          <div className='flex items-baseline text-xl space-grotesk-regular text-white leading-tight'>
            <div className='text-coldsnap4 leading-tight font-bold'>01</div>
            <span className='px-1 text-night1'>/</span>
            <span className='font-bold'> 03</span>
          </div>
          <div className='font-bold text-white space-grotesk-regular [text-shadow:_0_13.5px_24.75px_rgb(10_13_36_/_30%),_0_2.25px_7.5px_rgb(10_13_36_/_20%)] text-5xl translate-y-3.5'>
            {words.team.title}
          </div>
          <div className='text-night1 space-grotesk-regular font-normal text-2xl lg:text-xl translate-y-7 leading-[31.5px]'>
            {words.team.desc}
          </div>
        </div>
        <div className='space-y-3.5 h-[40rem] flex items-start justify-center flex-col -mt-[10.75rem] ml-[1rem]'>
          <div className='flex items-baseline text-xl space-grotesk-regular text-white mt-1'>
            <div className='text-coldsnap4 leading-tight font-bold'>02</div>
            <span className='px-1 text-night1'>/</span>
            <span className='font-bold'> 03</span>
          </div>
          <div className='font-bold text-white space-grotesk-regular [text-shadow:_0_13.5px_24.75px_rgb(10_13_36_/_30%),_0_2.25px_7.5px_rgb(10_13_36_/_20%)] text-5xl translate-y-3.5'>
            {words.competitive.title}
          </div>

          <div className='text-night1 space-grotesk-regular font-normal text-2xl lg:text-xl translate-y-8 leading-tight'>
            {words.competitive.desc}
          </div>
        </div>
        <div className='space-y-3 h-[40rem] flex items-start justify-center flex-col -mt-[0.5rem] ml-[1rem]'>
          <div className='flex items-baseline text-xl space-grotesk-regular text-white'>
            <div className='text-coldsnap4 leading-tight font-bold'>03</div>
            <span className='px-1 text-night1'>/</span>
            <span className='font-bold'> 03</span>
          </div>
          <div className='font-bold text-white space-grotesk-regular [text-shadow:_0_13.5px_24.75px_rgb(10_13_36_/_30%),_0_2.25px_7.5px_rgb(10_13_36_/_20%)] text-5xl translate-y-4'>
            {words.icebreakers.title}
          </div>
          <div className='text-night1 space-grotesk-regular font-normal text-2xl lg:text-xl translate-y-8 leading-[31.5px]'>
            {words.icebreakers.desc}
          </div>
        </div>
      </div>

      {/* image column */}
      <div className='relative flex-1 flex flex-col gap-10'>
        <div className='relative h-fit flex gap-10'>
          <div className='relative flex-1 flex-col space-y-10'>
            <div className='relative w-full h-[540px] bg-night5/50 rounded-[20px] overflow-hidden'>
              <VideoPlayer
                autoplay={true}
                src={`https://res.cloudinary.com/prod-internet-game/video/upload/${optimizationQueryLower}${teamBuildingGames[0]}.webm`}
                thumbnail={`https://res.cloudinary.com/prod-internet-game/video/upload/${previewImageOptimization}${teamBuildingGames[0]}.jpg`}
              />
            </div>
            <div className='relative w-full h-[620px] bg-night5/50 rounded-[20px] overflow-hidden'>
              <VideoPlayer
                autoplay={true}
                src={`https://res.cloudinary.com/prod-internet-game/video/upload/${optimizationQueryHigher}${competetiveGames[0]}.webm`}
                thumbnail={`https://res.cloudinary.com/prod-internet-game/video/upload/${previewImageOptimization}${competetiveGames[0]}.jpg`}
              />
            </div>
          </div>
          <div className='relative flex-1 flex-col space-y-10'>
            <div className='relative w-full h-[620px] bg-night5/50 rounded-[20px] overflow-hidden'>
              <VideoPlayer
                autoplay={true}
                src={`https://res.cloudinary.com/prod-internet-game/video/upload/${optimizationQueryHigher}${teamBuildingGames[1]}.webm`}
                thumbnail={`https://res.cloudinary.com/prod-internet-game/video/upload/${previewImageOptimization}${teamBuildingGames[1]}.jpg`}
              />
            </div>
            <div className='relative w-full h-[540px] bg-night5/50 rounded-[20px] overflow-hidden'>
              <VideoPlayer
                autoplay={true}
                src={`https://res.cloudinary.com/prod-internet-game/video/upload/${optimizationQueryLower}${competetiveGames[1]}.webm`}
                thumbnail={`https://res.cloudinary.com/prod-internet-game/video/upload/${previewImageOptimization}${competetiveGames[1]}.jpg`}
              />
            </div>
          </div>
        </div>

        <div className='relative w-full h-[550px] bg-night5/50 rounded-[20px] overflow-hidden'>
          <VideoPlayer
            autoplay={true}
            src={`https://res.cloudinary.com/prod-internet-game/video/upload/${optimizationQueryHorizontal}${icebreakerGames[0]}.webm`}
            thumbnail={`https://res.cloudinary.com/prod-internet-game/video/upload/${previewImageOptimization}${icebreakerGames[0]}.jpg`}
          />
        </div>
      </div>
    </div>
  );
};

export default TheGamesScrollDesktop;
