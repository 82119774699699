import React from 'react';

interface VerticalHeatwaveProps {}

const VerticalHeatwave: React.FC<VerticalHeatwaveProps> = () => {
  return (
    <div className='fixed inset-0 pointer-events-none'>
      <div className='hidden lg:flex absolute h-full w-[0.063rem] bg-heatwave7 opacity-10 left-[17.5%]' />
      <div className='absolute h-full w-[0.063rem] bg-heatwave7 opacity-10 left-[34.25%] ' />
      <div className='hidden lg:flex absolute h-full w-[0.063rem] bg-heatwave7 opacity-10 left-1/2' />
      <div className='absolute h-full w-[0.063rem] bg-heatwave7 opacity-10 right-[33.75%] ' />
      <div className='hidden lg:flex absolute h-full w-[0.063rem] bg-heatwave7 opacity-10 right-[17.5%] ' />
    </div>
  );
};

export default VerticalHeatwave;
