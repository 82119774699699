type GradientTextProps = {
  textArray: string[];
};

const GradientText = ({ textArray }: GradientTextProps) => {
  return (
    <div
      style={{ WebkitTextStroke: '1px white' }}
      className='gradient-shine bg-l whitespace-nowrap space-grotesk-bold text-[3rem] lg:text-[5rem] text-shadow-[0_0_13px_rgba(255,255,255,0.3)] bg-[linear-gradient(121deg,_rgba(255,255,255,0.5)_36%,_#ffffff_50%,_rgba(255,255,255,0.5)_64%)] bg-[length:400%_100%] bg-clip-text text-transparent mix-blend-plus-lighter animate-bg-move'
    >
      <h1>
        {textArray.map((text, index) => (
          <div
            key={index}
            className={
              index < 2 ? 'leading-[1] sm:leading-[0.85]' : 'leading-[1.3] sm:leading-[1.3]'
            }
          >
            {text}
          </div>
        ))}
      </h1>
    </div>
  );
};

export default GradientText;
