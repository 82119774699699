import { useEffect } from 'react';

import Router, { useRouter } from 'next/router';

function saveScrollPos(url: string) {
  const scrollPos = { x: window.scrollX, y: window.scrollY };
  sessionStorage.setItem(url, JSON.stringify(scrollPos));
}

function restoreScrollPos(url: string) {
  const scrollPos = JSON.parse(sessionStorage.getItem(url) as string);
  if (scrollPos) {
    window.scrollTo(scrollPos.x, scrollPos.y);
  } else {
    window.scrollTo(0, 0);
  }
}

export default function usePreserveScroll() {
  const router = useRouter();
  useEffect(() => {
    if ('scrollRestoration' in window.history && router.pathname.startsWith('/blog')) {
      let shouldScrollRestore = false;
      window.history.scrollRestoration = 'manual';
      restoreScrollPos(router.asPath);

      const onRouteChangeStart = () => {
        saveScrollPos(router.asPath);
      };

      const onRouteChangeComplete = (url: string) => {
        if (shouldScrollRestore) {
          shouldScrollRestore = false;
          restoreScrollPos(url);
        }
      };

      Router.events.on('routeChangeStart', onRouteChangeStart);
      Router.events.on('routeChangeComplete', onRouteChangeComplete);
      Router.beforePopState(() => {
        shouldScrollRestore = true;
        return true;
      });

      return () => {
        Router.events.off('routeChangeStart', onRouteChangeStart);
        Router.events.off('routeChangeComplete', onRouteChangeComplete);
        Router.beforePopState(() => true);
      };
    }
  }, [router]);

  const clearPreserveScroll = () => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key?.startsWith('/blog')) {
        sessionStorage.removeItem(key);
      }
    }
  };

  return clearPreserveScroll;
}
