import React from 'react';
import Image from 'next/legacy/image';
import { CTA } from '../components/CTA';
import withLandingPageSection from '~/components/common/HOC/withLandingPageSection';
import useUpdateUrlHash from '../hooks/useUpdateUrlHash';

interface FooterProps {
  inView?: boolean;
}

const Footer: React.FC<FooterProps> = ({ inView }) => {
  useUpdateUrlHash(inView, 'clear');

  return (
    <div className='relative lg:mt-10 h-[80vh] lg:h-[90vh] 2xl:h-[1000px] w-full flex items-center justify-center overflow-hidden'>
      <div className='flex flex-col items-center z-10 relative'>
        <div className='absolute -inset-x-1/3 -inset-y-[125%] lg:-inset-1/4 lg:-inset-y-[50%] opacity-30'>
          <Image
            src='/assets/landingB2BAug23/theGames/the_games_gradient.webp'
            alt='gradient'
            layout='fill'
          />
        </div>
        <div className='relative w-full flex items-center justify-center max-w-[60rem]'>
          <div className='relative h-[4.5rem] w-[22rem] sm:h-[7.5rem] sm:w-[38.5rem] lg:h-[14rem] lg:w-[92rem] lg:scale-x-[1.06] lg:scale-y-[1.03] ml-[0.5rem] lg:ml-[0.5rem]'>
            <span className='hidden lg:flex'>
              <Image
                src='/assets/landingB2BAug23/footer/lets_play.webp'
                alt='lets play neon display'
                layout='fill'
                className='object-contain '
                quality={90}
              />
            </span>

            <span className='lg:hidden'>
              <Image
                src='/assets/landingB2BAug23/footer/lets_play_mobile.webp'
                alt='lets play neon display'
                layout='fill'
                quality={90}
              />
            </span>
          </div>
        </div>
        <div className='text-white text-center space-grotesk-regular px-2 sm:px-8 font-bold text-[1.438rem] lg:leading-[2.5rem] leading-tight lg:text-[2.5rem] [text-shadow:_0px_18px_33px_rgb(10_13_36_/_0%),_0px_3px_10px_/_20%]  lg:translate-y-[0.75rem] lg:translate-x-1.5 mt-[1rem] -ml-[0.125rem] pb-[1.5rem]'>
          Join the most fun place on the internet
        </div>
        <div className='flex justify-center w-full px-5 lg:px-0 md:mt-[3rem]'>
          <CTA />
        </div>
      </div>
    </div>
  );
};
export default withLandingPageSection(Footer);
