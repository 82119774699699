import React from 'react';
import Image from 'next/legacy/image';
import { THE_GAMES } from '../../sectionNames';
import useImageLoader from '~/hooks/performance/useImageLoader';
import { useTailwindBreakpoints } from '~/hooks/ui/useTailwindBreakpoints';
import withLandingPageSection from '~/components/common/HOC/withLandingPageSection';
import useUpdateUrlHash from '../../hooks/useUpdateUrlHash';
import TheGamesScroll from './TheGamesScroll';

interface TheGamesSectionProps {
  inView?: boolean;
  version?: string;
}

const TheGames: React.FC<TheGamesSectionProps> = ({ inView = false, version }) => {
  const { lg, md } = useTailwindBreakpoints();

  useUpdateUrlHash(inView, THE_GAMES);

  const imagesUrlsOfNextSection = lg
    ? [
        '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FhowItWorks%2Fui_community.webp&w=3840&q=90',
        '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FhowItWorks%2Fui_schedule.webp&w=3840&q=90',
        '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FhowItWorks%2Fui_start_playing.webp&w=3840&q=90',
      ]
    : md
      ? [
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FhowItWorks%2Fui_community.webp&w=2048&q=90',
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FhowItWorks%2Fui_schedule.webp&w=2048&q=90',
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FhowItWorks%2Fui_start_playing.webp&w=2048&q=90',
        ]
      : [
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FhowItWorks%2Fui_community.webp&w=1080&q=90',
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FhowItWorks%2Fui_schedule.webp&w=1080&q=90',
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FhowItWorks%2Fui_start_playing.webp&w=1080&q=90',
        ];

  // Preload images of next section
  useImageLoader(inView, [
    '/assets/landingB2BAug23/shapes/outline_diamond.svg',
    '/assets/landingB2BAug23/shapes/pixels_5.svg',
    '/assets/landingB2BAug23/shapes/pixels_4.svg',
    '/assets/landingB2BAug23/shapes/outline_hexagon.svg',
    '/assets/landingB2BAug23/shapes/outline_triangle.svg',
    '/assets/landingB2BAug23/shapes/pixels_1.svg',
    ...imagesUrlsOfNextSection,
  ]);

  return (
    <div
      id={THE_GAMES}
      className='-mt-14 lg:-mt-5 pb-[3.25rem] md:pb-0 md:pt-[6.5rem] lg:pt-0 h-fit scroll-mt-[5rem] lg:scroll-mt-0'
    >
      <div className='relative flex flex-col items-center z-10 lg:pt-[8rem] pt-[2.5rem] lg:pb-[0rem]'>
        <div className='absolute inset-0 left-0 top-0 bg-checkered bg-[length:4px_4px] opacity-[0.04] [background-position:0_0,_2px_2px] pointer-events-none z-10' />

        {/* People */}
        <div className='absolute h-fit w-full top-[0.5rem] lg:top-[1rem] z-[1] container left-1/2  -translate-x-[50%] max-w-xl lg:max-w-[1520px]'>
          <div className='absolute h-16 w-16 lg:h-24 lg:w-24 overflow-hidden lg:rounded-3.5xl top-[17rem] rounded-2.5xl lg:top-[50.25rem] lg:left-[68.5rem] xl:left-[80%] left-[1.25rem]'>
            <Image
              layout='fill'
              src='/assets/landingB2BAug23/theGames/people/person4.webp'
              alt='a happy internet game player'
              quality={90}
            />
          </div>
          <div className='absolute h-12 w-12 lg:h-16 lg:w-16 lg:rounded-2.5xl overflow-hidden lg:left-[19.5rem] rounded-xl left-[3.75rem] top-[0.25rem] lg:top-[46.25rem]'>
            <Image
              layout='fill'
              src='/assets/landingB2BAug23/theGames/people/person3.webp'
              alt='a happy internet game player'
            />
          </div>
          <div className='absolute h-8 w-8 lg:h-12 lg:w-12 overflow-hidden lg:rounded-2xl rounded-xl right-[7rem] top-[3rem] lg:right-[36.75rem] lg:top-[16rem]'>
            <Image
              layout='fill'
              src='/assets/landingB2BAug23/theGames/people/person2.webp'
              alt='a happy internet game player'
            />
          </div>
          <div className='absolute h-12 w-12 lg:h-24 lg:w-24  overflow-hidden lg:rounded-[2rem] rounded-2.5xl right-[3.5rem] top-[17.5rem] lg:left-[34.75rem] lg:top-[9.5rem]'>
            <Image
              layout='fill'
              src='/assets/landingB2BAug23/theGames/people/person1.webp'
              alt='a happy internet game player'
              quality={90}
            />
          </div>
        </div>

        {/* Game Images */}
        {/* <div className='absolute h-full w-full xl:max-w-[1520px]'>
          <div className='absolute h-[14.406rem] w-48 left-[17rem] top-0 z-[1] hidden lg:inline'>
            <Image
              layout='fill'
              alt='the games 1'
              src='/assets/landingB2BAug23/theGames/game1.webp'
            />
          </div>
          <div className='absolute h-[16.12rem] w-[13.5rem] right-[6.25rem] top-[3.5rem] z-[1] hidden lg:inline'>
            <Image
              layout='fill'
              alt='the games 2'
              src='/assets/landingB2BAug23/theGames/game2.webp'
            />
          </div>
          <div className='absolute h-96 w-80  top-[28.75rem] left-[39rem] z-[1] hidden lg:inline'>
            <Image
              layout='fill'
              alt='the games 3'
              src='/assets/landingB2BAug23/theGames/game3.webp'
            />
          </div>
        </div> */}

        {/* Gradient */}
        <div className='absolute h-[20rem] w-[20rem] lg:h-[45rem] lg:w-[45rem] top-[5rem] lg:top-[23rem] left-1/2 -translate-x-[50%] z-[0] lg:scale-[1.25]'>
          <div className='absolute -inset-1/3 scale-125 md:scale-150 lg:scale-125 z-0 lg:opacity-30 opacity-20'>
            <Image
              src='/assets/landingB2BAug23/theGames/the_games_gradient.webp'
              alt='gradient'
              layout='fill'
            />
          </div>
        </div>

        {/* Title */}
        <div className='relative flex flex-col items-center justify-center -space-y-[2rem] md:-space-y-1 lg:space-y-3 mt-6 mb-10 z-[1] lg:mb-[24.75rem]'>
          <div className='relative w-full h-40 lg:mt-72  flex items-center justify-center lg:max-w-[68.75rem] '>
            <div className='relative h-[80px] tablet:h-[140px] lg:h-[204px] w-full scale-x-[0.94] scale-y-[0.95] lg:scale-[1.06] lg:scale-y-[1.115] lg:scale-x-[1.15] lg:-translate-y-[2.25rem]'>
              <Image
                src='/assets/landingB2BAug23/theGames/the_games.webp'
                alt='the games title'
                layout='fill'
                objectFit='contain'
                quality={90}
              />
            </div>
          </div>
          <div className='relative space-grotesk-regular font-bold text-white [text-shadow:_0_8px_14px_rgb(0_0_0_/_10%)] text-[1.5rem] md:text-3xl lg:text-5xl text-center px-5 lg:px-0'>
            We&apos;ll bring the games, you bring the players.
          </div>
        </div>

        <TheGamesScroll version={version} />
      </div>
    </div>
  );
};

export default withLandingPageSection(TheGames);
