import Image from 'next/legacy/image';
import React from 'react';
import useImageLoader from '~/hooks/performance/useImageLoader';
import { useTailwindBreakpoints } from '~/hooks/ui/useTailwindBreakpoints';
import withLandingPageSection from '~/components/common/HOC/withLandingPageSection';
import { CTA } from '../components/CTA';
import { FEATURES } from '../sectionNames';
import useUpdateUrlHash from '../hooks/useUpdateUrlHash';

interface FeaturesProps {
  inView?: boolean;
  sectionClicked?: string | undefined;
}

export const Features: React.FC<FeaturesProps> = ({ inView = false }) => {
  const { lg } = useTailwindBreakpoints();
  useUpdateUrlHash(inView, FEATURES);
  // Preload images of next section
  useImageLoader(
    inView,
    lg
      ? [
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FtheGames%2Fthe_games.webp&w=3840&q=90',
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2Fpeople%2Famsterdam.webp&w=2048&q=90',
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2Fpeople%2Faustin.webp&w=2048&q=75',
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2Fpeople%2Flondon.webp&w=2048&q=75',
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2Fpeople%2Fsydney.webp&w=2048&q=90',
        ]
      : [
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FtheGames%2Fthe_games.webp&w=2048&q=90',
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2Fpeople%2Famsterdam.webp&w=1080&q=90',
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2Fpeople%2Faustin.webp&w=1080&q=75',
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2Fpeople%2Flondon.webp&w=1080&q=75',
          '/_next/image?url=%2Fassets%2FlandingB2BAug23%2Fpeople%2Fsydney.webp&w=1080&q=90',
        ]
  );

  const features = [
    {
      imageSrc: '/assets/landingB2BAug23/features/combined/games.webp',
      title: 'Games Everyone Will Love',
      description:
        'From brain teasers to adrenaline-pumping arcade nail-biters, our constantly expanding library of game genres has something for everybody.',
    },
    {
      reverseOrder: true,
      imageSrc: '/assets/landingB2BAug23/features/combined/voicechat.webp',
      title: 'Built in Voice Chat',
      description:
        'Scream, laugh, joke, and troll each other with zero setup—no need to use Zoom on the side.',
    },
    {
      imageSrc: '/assets/landingB2BAug23/features/combined/pie.webp',
      title: 'Super Easy To Play',
      description:
        'All you have to do is create a game room and send an invite link. Then anyone can pick a game and start playing -- time—no supervision required.',
    },
    {
      reverseOrder: true,
      imageSrc: '/assets/landingB2BAug23/features/combined/tournament.webp',
      title: 'Tournaments With Prizes',
      description:
        'Create a tournament in 3 clicks, put up a prize on the line, and let the battle commence!',
    },
    /*
    {
      imageSrc: '/assets/landingB2BAug23/features/combined/schedule.webp',
      title: 'Schedule Your Play Time',
      description:
        "Creating a recurring game night couldn't be easier. Just choose a time, and we'll send out calendar invites, ping your Slack, and open the game room automatically.",
    },
    */
  ];

  return (
    <div
      id={FEATURES}
      className='relative z-10 pt-[3rem] lg:pt-[0rem] sm:pb-[4rem] scroll-mt-[2.5rem] lg:scroll-mt-0'
    >
      <div className='flex flex-col md:px-12 h-fit w-full items-center justify-center relative xl:container'>
        <div className='text-white space-grotesk-bold font-bold mb-[5rem]  text-[1.5rem] md:text-[3rem] lg:text-[3rem] lg:mt-[9.5rem] lg:mb-[8rem] translate-y-0.5 relative z-[5]'>
          Features
        </div>
        <div className='space-y-20 w-full'>
          {features.map((feature, index) => (
            <div
              key={index}
              className='w-full md:max-w-[1000px] lg:max-w-[1200px] mx-auto flex flex-col md:flex-row justify-between items-center'
            >
              <div
                className={`flex w-full max-w-[28.125rem] sm:max-w-[27rem] ${
                  feature.reverseOrder ? 'lg:ml-[1.5rem] md:order-last' : 'lg:mr-[1.5rem]'
                } flex-col justify-center space-y-4 relative z-[5] px-4.5 lg:px-0`}
              >
                <div className='text-white space-grotesk-bold font-bold text-[2rem]  lg:text-5xl lg:leading-[100%] leading-[2rem]'>
                  {feature.title}
                </div>
                <div className='text-night1 space-grotesk-regular font-normal text-xl'>
                  {feature.description}
                </div>
              </div>

              <div
                className={`relative w-full max-w-lg lg:max-w-full mx-auto h-[16.75rem] md:h-[26rem] lg:h-[34rem] lg:min-w-[48rem] xl:min-w-[51rem] translate-y-6 ${
                  feature.reverseOrder
                    ? 'lg:-translate-x-8'
                    : '-translate-x-[0.5rem] lg:translate-x-8'
                }`}
              >
                <Image
                  layout='fill'
                  alt={`feature ${index + 1}`}
                  src={feature.imageSrc}
                  objectFit='contain'
                  quality={90}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='relative mx-auto flex justify-center mt-20 md:mt-[8rem] lg:mt-[9rem] px-5 lg:ml-0'>
        <CTA />
      </div>
    </div>
  );
};

export default withLandingPageSection(Features);
