import { animated } from '@react-spring/web';
import Link from 'next/link';

const PageFooter = () => {
  return (
    <animated.div className='border-t-1 border-heatwave1/40 flex flex-col lg:flex-row items-center justify-between py-1.5 backdrop-blur-[50px] z-10 pt-[1.125rem] lg:pt-[0.30rem] pb-8 lg:pb-3.5'>
      <animated.div className='flex flex-col lg:flex-row  justify-center items-center w-full space-y-5 lg:space-y-0 lg:space-x-10 lg:-translate-x-8 my-1'>
        <div className='space-mono-regular text-sm font-normal text-night1 leading-[20px] [text-shadow:_0_1px_1px_rgb(0_0_0_/_30%)] drop-shadow-[0_1px_1px_rgba(0,0,0,0.3)]'>
          © Internet Game {new Date().getFullYear()}
        </div>
        <Link href='/blog' legacyBehavior>
          <span className='space-mono-regular hover:cursor-pointer text-sm font-normal text-night1 leading-[20px] [text-shadow:_0_1px_1px_rgb(0_0_0_/_30%)] drop-shadow-[0_1px_1px_rgba(0,0,0,0.3)]'>
            Blog
          </span>
        </Link>
      </animated.div>
    </animated.div>
  );
};

export default PageFooter;
