import Image from 'next/legacy/image';
import React, { useState } from 'react';
import { CTA } from '~/components/B2BLanding/components/CTA';
import PlayButton from '~/components/B2BLanding/components/Playbutton';
import VideoModal from '~/components/B2BLanding/components/VideoModal';
import withLandingPageSection from '~/components/common/HOC/withLandingPageSection';
import { VideoPlayer } from '~/components/common/VideoPlayer';
import useImageLoader from '~/hooks/performance/useImageLoader';
import { useTailwindBreakpoints } from '~/hooks/ui/useTailwindBreakpoints';
import GradientText from '../GradientText';

interface HeroProps {
  version?: string;
}

const dictionary = {
  default: {
    subtitle: `Play from a huge library of casual, multiplayer mini-games — all in browser. No downloads. Just jump in and play.`,
  },
  company: {
    subtitle: `Say goodbye to Zoom Trivia. Play games that are actually fun with your entire team —
                whether you're 10 people or 10,000.`,
  },
  communities: {
    subtitle: `Say goodbye to crappy Discord bots. Play games that are actually fun with your entire community —
                whether you're 20 people or 200,000.`,
  },
};

const Hero: React.FC<HeroProps> = ({ version }) => {
  const [hoverPlayButton, setHoverPlayButton] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const words = dictionary[version as keyof typeof dictionary] || dictionary.default;

  useImageLoader(true, [
    '/assets/landingB2BAug23/QuickFireSection/keyboard_keys_desktop.svg',
    '/assets/landingB2BAug23/QuickFireSection/keyboard_keys_mobile.svg',
    '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FQuickFireSection%2Fbrowser.webp&w=1080&q=90',
    '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FQuickFireSection%2Froom_friday_beers.webp&w=640&q=90',
    '/_next/image?url=%2Fassets%2FlandingB2BAug23%2FQuickFireSection%2Froom_breakroom.webp&w=640&q=90',
  ]);

  const { lg, md } = useTailwindBreakpoints();

  const desktopOptimization = 'c_fill,g_center,q_100,h_710,w_696';
  const tabletOptimization = 'c_fill,g_center,q_100,h_710,w_768';
  const mobileOptimization = 'g_center,c_fill,q_90,h_374,w_312';
  const imageOptimization = 'q_auto,f_auto,c_fill';

  const optimization = lg ? desktopOptimization : md ? tabletOptimization : mobileOptimization;
  const videoFormat = lg ? 'webm' : 'mp4';

  const characterHeight = lg ? 495 : 293;
  const characterWidth = lg ? 293 : 164;

  const image = lg
    ? '/assets/landingB2BAug23/hero/hero_bg_gradient.webp'
    : '/assets/landingB2BAug23/hero/gradient_hero_mobile.webp';

  const video = '/v1696882494/b2b-landing-page-august-23/hero-clip-10seconds'; // ! when editing DON'T add a file extension

  return (
    <div
      id='top'
      className={`relative flex w-full lg:h-[calc(100vh-182px)] lg:max-h-[1000px] flex-grow items-center  justify-center z-[1]`}
    >
      <div className='absolute w-[100%] h-[100%] top-[-60px] lg:top-[-30px]'>
        <Image src={image} alt='gradient' layout='fill' priority={true} />
      </div>
      <div
        className={`flex relative w-full h-full flex-col lg:flex-row items-center space-y-[2.25rem] mx-0 px-0  flex-grow mt-[1.25 rem] lg:mt-0 lg:pt-[5rem] max-w-[1520px]`}
      >
        <div className='px-10 md:pl-24 md:pr-28 md:pb-[3rem] lg:pb-[5rem] flex items-center justify-center '>
          <div className='relative flex flex-col max-w-[560px]'>
            <div className='relative'>
              <div
                className={`flex flex-col -space-y-6 text-center lg:text-left mt-1.5 md:mt-7 lg:mt-1.5 lg:mb-[1.75rem] relative mb-2`}
              >
                {version == 'remote-teams' && (
                  <GradientText textArray={['The virtual', 'game room for', 'remote teams']} />
                )}
                {version == 'communities' && (
                  <GradientText textArray={['The virtual', 'game room for', 'communities']} />
                )}
                {!version && <GradientText textArray={['The browser', 'party game', 'platform']} />}
              </div>
              <div
                className={`text-white space-grotesk-regular font-medium [text-shadow:_0_18px_33px_rgb(10_13_36_/_30%)] leading-tight relative text-center lg:text-left text-lg my-[0.75rem] md:text-[1.5rem] md:mt-6 lg:-mt-2 lg:mb-[1rem] lg:pr-8`}
              >
                {words.subtitle}
              </div>
              <div
                className={`w-full flex flex-col justify-between translate-y-4 md:flex-row md:justify-center md:space-x-4 mt-1 md:mt-6 lg:mt-0 relative `}
              >
                <div className='flex-1 flex flex-col justify-center items-center lg:items-start lg:justify-start'>
                  <CTA />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='relative flex items-center justify-center h-[452px] md:h-[710px] w-full lg:w-1/2 px-5 pt-5 lg:p-0 '>
          <div
            onMouseEnter={() => setHoverPlayButton(true)}
            onMouseLeave={() => setHoverPlayButton(false)}
            onMouseDown={() => setShowModal(true)}
            className={`relative rounded-2.5xl overflow-hidden h-full w-full lg:px-0 max-w-3xl lg:max-w-[43.5rem] lg:mr-[1rem] mt-1.5 lg:-mt-[2.5rem] flex-grow outline outline-2 outline-transparent hover:outline-coldsnap4 hover:cursor-pointer hover:shadow-blog-card-hover transition-all ease-[cubic-bezier(0.51, 0.02, 0, 0.99)] duration-[600ms]`}
          >
            {/* <Players /> */}

            <VideoPlayer
              autoplay={true}
              src={`https://res.cloudinary.com/prod-internet-game/video/upload/${optimization}${video}.${videoFormat}`}
              thumbnail={`https://res.cloudinary.com/prod-internet-game/video/upload/${imageOptimization}${video}.jpg`}
              preloadThumbnail={true}
            />
            <PlayButton isActive={hoverPlayButton} />
          </div>
          <div className='max-lg:sticky max-lg:float-left bottom-0 max-lg:w-0  max-lg:h-[200px] lg:absolute lg:right-auto lg:bottom-0 lg:left-0 lg:translate-x-[-40%] pointer-events-none'>
            <div className='max-lg:w-[164px] max-lg:translate-x-[-80%]'>
              <Image
                src='/assets/landingB2BAug23/hero/character.webp'
                alt='gradient'
                width={characterWidth}
                height={characterHeight}
                priority={true}
              />
            </div>
          </div>
        </div>
        <VideoModal open={showModal} handleClose={() => setShowModal(false)} />
      </div>
    </div>
  );
};
export default withLandingPageSection(Hero);
