import { Fade, Modal as MUIModal } from '@mui/material';
import React from 'react';

export interface ModalProps {
  children: React.ReactElement;
  handleClose: any;
  open: boolean;
}

export const Modal: React.FC<ModalProps> = ({ children, handleClose, open }) => {
  return (
    <MUIModal open={open} onClose={handleClose} closeAfterTransition>
      <Fade in={open}>{children}</Fade>
    </MUIModal>
  );
};
