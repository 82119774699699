import React from 'react';
import { Icon } from '~/components/common/Icon';

type PlayButtonProps = {
  isActive?: boolean;
};

const PlayButton: React.FC<PlayButtonProps> = ({ isActive = false }) => {
  return (
    <div
      className={`absolute top-1/2 left-1/2 h-fit w-fit translate-x-[-50%] translate-y-[-50%] hover:cursor-pointer pointer-events-none`}
    >
      <div
        className={`from-[rgba(0,0,0,0.36)] to-[rgba(0,0,0,0.55)] shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)] transition-all ease-in-out duration-[300ms] relative flex items-center justify-center p-8 overflow-hidden rounded-full bg-gradient-to-b ${isActive && 'before:inset-0 before:bg-gradient-to-b before:from-transparent before:to-buttonSecondary before:opacity-[66%] to-[rgba(94,252,214,0.6)] from-buttonPrimary shadow-[inset_0_0_0_2px_rgba(94,252,214,1),0_0_24px_rgba(94,252,214,1)]'} `}
      >
        <div
          className='transition-all ease-in-out duration-[300ms]'
          style={
            isActive
              ? {
                  filter: 'drop-shadow(0 0 10px rgba(94, 252, 214, 1)',
                  stroke: 'rgba(94, 252, 214, 1)',
                  strokeWidth: '1px',
                  transform: 'scale(1.2)',
                }
              : {
                  stroke: 'rgba(0, 0, 0, 0.6)',
                  strokeWidth: '0.5px',
                  transform: 'scale(1)',
                }
          }
        >
          <Icon name='play' color={isActive ? 'buttonSecondary' : 'white'} size='xl' />
        </div>
      </div>
    </div>
  );
};

export default PlayButton;
