import { useEffect, useState } from 'react';

const useImageLoader = (load: boolean, imageUrls: string[]) => {
  const [loadedImages, setLoadedImages] = useState<string[]>([]);
  const [triggered, setTriggered] = useState<boolean>(false);

  useEffect(() => {
    if (load && !triggered) {
      setTriggered(true);
      imageUrls.forEach((url) => {
        const image = new Image();
        image.src = url;

        image.onload = () => {
          if (!loadedImages.includes(url)) {
            setLoadedImages((prevImages) => [...prevImages, url]);
          }
        };
      });
    }
  }, [load, imageUrls, loadedImages, triggered]);

  return loadedImages;
};

export default useImageLoader;
