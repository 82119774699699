import dynamic from 'next/dynamic';
import Image from 'next/legacy/image';
import React, { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import type { ReactPlayerProps } from 'react-player';

const ReactPlayer = dynamic<ReactPlayerProps>(() =>
  import('react-player').then((mod) => mod.default)
);

interface VideoPlayerProps {
  src: string | string[];
  thumbnail?: string;
  onPlayChange?: (isPlaying: boolean) => void;
  preloadThumbnail?: boolean;
  autoplay?: boolean;
  muted?: boolean;
  play?: boolean;
  disableClickPlay?: boolean;
  hasControls?: boolean;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  thumbnail,
  onPlayChange,
  preloadThumbnail,
  autoplay = false,
  muted = true,
  play,
  disableClickPlay,
  hasControls = false,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isInitialPlay, setIsInitialPlay] = useState<boolean>(true);
  const [startPlaying, setStartPlaying] = useState<boolean>(autoplay);

  useEffect(() => {
    if (onPlayChange) {
      onPlayChange(startPlaying);
    }
  }, [startPlaying, onPlayChange]);

  return (
    <div
      className='relative w-full h-full cursor-pointer'
      onClick={() => {
        if (disableClickPlay) return;
        setStartPlaying((playing) => !playing);
        setIsInitialPlay(false);
      }}
    >
      <div className='absolute inset-0 overflow-hidden flex justify-center cursor-pointer'>
        <div className='relative h-full w-full'>
          {thumbnail && thumbnail !== '' && (
            <Image
              src={thumbnail}
              layout='fill'
              alt='Trailer thumbnail of awesome games'
              priority={preloadThumbnail}
              className={`${isPlaying ? 'opacity-0' : 'opacity-100'} transition-opacity delay-1000`}
              objectFit='cover'
            />
          )}
        </div>
      </div>

      <InView
        as='div'
        className='absolute inset-1/2 cursor-pointer'
        onChange={(inView) => {
          if (!isInitialPlay) {
            setStartPlaying(inView);
          }
          if (autoplay && inView && isInitialPlay) {
            setStartPlaying(true);
            setIsInitialPlay(false);
          }
        }}
      />

      <div
        className={`player-wrapper h-full w-full opacity-0 ${
          (!isInitialPlay || isPlaying) && 'opacity-100'
        } transition-opacity duration-700 ${!hasControls && 'pointer-events-none'}`}
      >
        {!isInitialPlay && (
          <ReactPlayer
            playsinline
            autoPlay={autoplay}
            muted={muted}
            loop={true}
            className='react-player'
            playing={play !== undefined ? play : startPlaying}
            url={src}
            controls={hasControls}
            onPlay={() => {
              setIsInitialPlay(false);
              setIsPlaying(true);
            }}
            onPause={() => setIsPlaying(false)}
            height='100%'
            width='100%'
          />
        )}
      </div>
    </div>
  );
};
