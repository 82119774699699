import React from 'react';

const Glitch = () => {
  return (
    <div className='w-full h-52 relative lg:pt-[4.25rem] pt-[2rem] z-0'>
      <div className="h-full w-full bg-[url('/assets/landingB2BAug23/glitch/glitch.svg')] bg-repeat-x relative z-[3]" />
      <div className="absolute h-20 w-full bg-[url('/assets/landingB2BAug23/glitch/coldsnap.svg')] bg-repeat-x z-[6] top-8 overflow-hidden" />
    </div>
  );
};

export default Glitch;
