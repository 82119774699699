import { animated, useSpringValue } from '@react-spring/web';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

interface WithVisibilityProps {
  noAnimation?: boolean;
}

// This HOC is specificially for the b2b landing page section to track Visibility and Transition the section in
const withLandingPageSection = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P & WithVisibilityProps> => {
  const WithVisibilityComponent: React.FC<P & WithVisibilityProps> = ({
    noAnimation,
    ...restProps
  }) => {
    const [ref, inView] = useInView({
      triggerOnce: false, // continuously observe
      threshold: 0.15,
    });

    const fadeInOpacity = useSpringValue(noAnimation ? 1 : 0, {
      config: { duration: 1000 },
      immediate: noAnimation,
    });

    useEffect(() => {
      if (inView && !noAnimation) {
        fadeInOpacity.start(1);
      }
    }, [fadeInOpacity, inView, noAnimation]);

    return (
      <div ref={ref} className='relative h-full'>
        <animated.div style={{ opacity: fadeInOpacity }}>
          <WrappedComponent {...(restProps as P)} inView={inView} />
        </animated.div>
      </div>
    );
  };

  // Set the displayName property
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WithVisibilityComponent.displayName = `WithVisibility(${wrappedComponentName})`;

  return WithVisibilityComponent;
};

export default withLandingPageSection;
