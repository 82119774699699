import { useEffect } from 'react';

const dispatchHashChangedEvent = () => {
  const event = new Event('customHashChanged');
  window.dispatchEvent(event);
};

const useUpdateUrlHash = (inView: boolean | undefined, section: string) => {
  useEffect(() => {
    if (inView) {
      if (section === 'clear') {
        history.replaceState(null, '', window.location.pathname);
      } else {
        history.replaceState(null, '', `#${section}`);
      }
      dispatchHashChangedEvent();
    }
  }, [inView, section]);
};

export default useUpdateUrlHash;
