import Image from 'next/legacy/image';
import React from 'react';
import { HeatwaveGradient } from '~/components/common/HeatwaveGradient';
import useImageLoader from '~/hooks/performance/useImageLoader';
import { useTailwindBreakpoints } from '~/hooks/ui/useTailwindBreakpoints';
import withLandingPageSection from '~/components/common/HOC/withLandingPageSection';
import { CTA } from '../components/CTA';
import Keyboard from '../components/Keyboard';

interface QuickFireFeatureProps {
  inView?: boolean;
}

const QuickFireFeature: React.FC<QuickFireFeatureProps> = ({ inView = false }) => {
  const { md, lg } = useTailwindBreakpoints();
  const imagesUrlsOfNextSection = lg
    ? [
        '/_next/image?url=%2Fassets%2FlandingB2BAug23%2Fglobe%2Fglobe.webp&w=3840&q=90',
        '/_next/image?url=%2Fassets%2FlandingB2BAug23%2Fglobe%2Fglobe_glow_compressed.webp&w=3840&q=90',
        '/_next/image?url=%2Fassets%2FlandingB2BAug23%2Fglobe%2Fglobe_people.webp&w=3840&q=90',
      ]
    : ['/_next/image?url=%2Fassets%2FlandingB2BAug23%2Fglobe%2Fglobe_mobile.webp&w=1200&q=90'];

  // Preload images of next section
  useImageLoader(inView, imagesUrlsOfNextSection);

  return (
    <div className='relative z-10 px-5 pt-[4rem] pb-0 lg:pt-[10.65rem] lg:pb-[0.5rem] tablet:max-w-[75%] lg:max-w-full left-[50%] -translate-x-[50%]'>
      <div className='flex flex-col m-auto max-w-[74.125rem] items-center justify-center'>
        <div className='relative h-[18rem] lg:h-[18.25rem] w-full flex flex-col items-center justify-start rounded-[1.25rem]  overflow-hidden -mt-3 bg-night5'>
          <div className='absolute w-[200%] h-[200%] lg:w-full -bottom-8 lg:bottom-0 left-1/2 translate-x-[-50%] m-auto blur-sm opacity-70 lg:opacity-80'>
            <Image
              src='/assets/landingB2BAug23/QuickFireSection/simple_gradient.webp'
              layout='fill'
              alt='gradient'
              priority={true}
              loading='eager'
            />
          </div>

          <div className='absolute h-full w-full  left-0 opacity-20'>
            <HeatwaveGradient
              gradient={{ fromOpacity: 100, toOpacity: 50 }}
              rounded='rounded-[1.25rem]'
            />
          </div>

          <Keyboard />

          <div className='relative text-white text-[2rem] lg:text-[2.5rem] font-bold space-grotesk-bold leading-[2.5rem] mt-[1.65rem] lg:mt-[2.55rem] lg:mb-0 z-[5] mb-[0.5rem]'>
            Simple to Play
          </div>
          <div className='space-grotesk-regular font-normal lg:leading-normal leading-tight mt-2.5 text-white text-[1.25rem] lg:text-xl px-5 lg:p-0 text-center lg:mt-[1rem] z-[5]'>
            Simple but insanely fun games with even easier controls. <br />
            Non-gamer friendly.
          </div>
        </div>

        <div className='flex flex-col lg:flex-row w-full lg:gap-x-10 lg:space-y-10 -space-y-3 -mt-[0.75rem] lg:mt-0'>
          <div className='relative flex flex-col h-[18rem] lg:h-[20.125rem] w-full lg:w-1/2 items-center justify-start rounded-[1.25rem] my-10 overflow-hidden bg-night5'>
            <div className='absolute h-full w-full left-0 opacity-20'>
              <HeatwaveGradient
                gradient={{ fromOpacity: 100, toOpacity: 5 }}
                rounded='rounded-[1.25rem]'
              />
            </div>

            <div className='absolute -inset-full -left-2/3 lg:top-0 top-0 scale-x-125 lg:scale-x-150 blur-sm'>
              <Image
                src='/assets/landingB2BAug23/QuickFireSection/zero_gradient.webp'
                layout='fill'
                alt='gradient'
                priority={true}
              />
            </div>

            <div className='absolute -bottom-[11.25rem] lg:-bottom-[10.75rem] h-full w-[94%] lg:w-[90%]'>
              <div className='relative h-[125px] lg:h-[150px] w- max-w-[775px] mx-auto flex justify-center'>
                <Image
                  src='/assets/landingB2BAug23/QuickFireSection/browser.webp'
                  alt='browser'
                  width={775}
                  height={228}
                  layout='intrinsic'
                  objectFit='contain'
                  quality={90}
                  loading='eager'
                />
              </div>
            </div>
            <div className='text-white font-bold text-[2rem] lg:text-[2.5rem] space-grotesk-bold leading-[2.5rem] z-[5] mt-[1.75rem] lg:mt-[2.5rem] mb-[0.5rem]'>
              Zero Friction
            </div>
            <div className='text-white text-[1.25rem] lg:text-xl font-normal space-grotesk-regular leading-tight lg:leading-[1.75rem] text-center z-[5] lg:mt-0 mt-2 px-3 max-w-[100%] lg:max-w-[65%]'>
              No downloads. No Zoom. Just click a link and play in the browser.
            </div>
          </div>

          <div className='relative flex flex-col h-[18rem] lg:h-[20.125rem] w-full lg:w-1/2 items-center justify-start rounded-[1.25rem] overflow-hidden bg-night5'>
            <div className='absolute h-full w-full  left-0 opacity-20'>
              <HeatwaveGradient
                gradient={{ fromOpacity: 100, toOpacity: 5 }}
                rounded='rounded-[1.25rem]'
              />
            </div>

            {/* Empty div for layout fix */}
            <div className='hidden lg:inline' />

            <div className='absolute -inset-full -left-2/3 lg:top-0 top-0 scale-x-125 lg:scale-x-150 blur-sm'>
              <Image
                src='/assets/landingB2BAug23/QuickFireSection/player_gradient.webp'
                layout='fill'
                alt='gradient'
                priority={true}
              />
            </div>

            <div className='absolute inset-x-0 -bottom-6 lg:bottom-1 space-x-2.5 flex items-center justify-center pl-8 lg:pl-0'>
              <div className='relative h-[200px] w-[285px] lg:h-[150px] lg:w-[255px] top-[3.25rem] lg:top-0 hidden md:inline'>
                <Image
                  src='/assets/landingB2BAug23/QuickFireSection/room_breakroom.webp'
                  width={253}
                  height={150}
                  layout='fixed'
                  alt='room image'
                  quality={90}
                  loading='eager'
                />
              </div>
              <div className='relative w-[285px] h-[200px] -left-1.5 md:left-0 lg:top-0 top-[3rem] lg:h-[150px] lg:w-[255px]'>
                <Image
                  src='/assets/landingB2BAug23/QuickFireSection/room_friday_beers.webp'
                  width={253}
                  height={150}
                  layout='fixed'
                  className='object-contain'
                  alt='room image'
                  quality={90}
                  loading='eager'
                />
              </div>
            </div>
            <div className='text-white font-bold text-[2rem] lg:text-[2.5rem] space-grotesk-bold leading-[2.5rem] z-[5] mt-[1.75rem] lg:mt-[2.5rem] mb-[0.75rem]'>
              No Player Limit
            </div>
            <div className='text-white text-[1.25rem] lg:text-xl font-normal space-grotesk-regular leading-tight lg:leading-[1.5rem] text-center z-[5] lg:-mt-[0.01rem] mt-2 px-3'>
              Have fun all together whether <br /> you&apos;re a group of 5 or 500.
            </div>
          </div>
        </div>

        <div className='flex w-full items-center justify-center py-[5rem] pb-[2.5rem] lg:py-[7.5rem] lg:w-[28rem]'>
          <CTA buttonOverride={md ? 'py-[1.5rem]' : ''} maxWidthOverride='lg:max-w-[28rem]' />
        </div>
      </div>
    </div>
  );
};
export default withLandingPageSection(QuickFireFeature);
