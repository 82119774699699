import React from 'react';
import { Button } from '~/components/common/Button/Button';
import { Modal, ModalProps } from '~/components/common/Modal';
import { VideoPlayer } from '~/components/common/VideoPlayer';
import { useTailwindBreakpoints } from '~/hooks/ui/useTailwindBreakpoints';

type VideoModalProps = Omit<ModalProps, 'children'> & {
  handleClose: () => void;
};

const VideoModal: React.FC<VideoModalProps> = ({ handleClose, ...props }) => {
  const { lg, md } = useTailwindBreakpoints();

  const desktopOptimization = 'c_fill,g_center,q_100,h_1080,w_1920';
  const tabletOptimization = 'c_fill,g_center,q_100,h_720,w_1280';
  const mobileOptimization = 'c_fill,g_center,q_90,h_180,w_320';
  const imageOptimization = 'q_auto,f_auto,c_fill';

  const optimization = lg ? desktopOptimization : md ? tabletOptimization : mobileOptimization;
  const videoFormat = lg ? 'webm' : 'mp4';

  const video = '/v1696882494/b2b-landing-page-august-23/hero-clip-full'; // ! when editing DON'T add a file extension

  const onClose = () => {
    handleClose();
  };

  return (
    <Modal {...props} handleClose={handleClose}>
      <div className='relative w-[100%] h-[100%] flex flex-auto py-10 lg:p-10 bg-black bg-opacity-60 backdrop-blur-md'>
        <div className='flex items-center justify-center rounded-lg overflow-hidden border-opacity-40 lg:border-2 border-white w-full h-auto'>
          <div className='relative w-full pt-[56.25%] lg:w-[100%] lg:h-[100%] lg:pt-0'>
            <div className='absolute top-0 left-0 w-full h-full'>
              <VideoPlayer
                autoplay={true}
                src={`https://res.cloudinary.com/prod-internet-game/video/upload/${optimization}${video}.${videoFormat}`}
                thumbnail={`https://res.cloudinary.com/prod-internet-game/video/upload/${imageOptimization}${video}.jpg`}
                preloadThumbnail={true}
                hasControls={true}
                muted={false}
              />
            </div>
          </div>
        </div>

        <div className='absolute right-5 top-5 [&_svg]:w-[42px] [&_svg]:h-[42px] [&_svg]:p-[8px]'>
          <Button
            onClick={onClose}
            icon='cross'
            type='info'
            rounded
            styleOverride='backdrop-blur-3xl rounded-[50%] hover:bg-white lg:border-opacity-40 lg:border-2 lg:border-white'
          />
        </div>
      </div>
    </Modal>
  );
};

export default VideoModal;
