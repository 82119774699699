import React from 'react';
import Image from 'next/legacy/image';

const Keyboard = () => {
  return (
    <div className='absolute h-full w-full top-0 left-0 z-[2] lg:ml-16'>
      <div className='absolute bottom-5 hidden sm:flex pr-8'>
        <Image
          src='/assets/landingB2BAug23/QuickFireSection/keyboard_keys_desktop.svg'
          width={998}
          height={586}
          alt='keyboard keys'
          loading='eager'
        />
      </div>

      <div className='absolute bottom-5 -inset-48 sm:hidden'>
        <div className='relative w-full h-full flex items-center justify-center'>
          <Image
            src='/assets/landingB2BAug23/QuickFireSection/keyboard_keys_mobile.svg'
            width={705}
            height={441}
            alt='keyboard keys'
            loading='eager'
          />
        </div>
      </div>
    </div>
  );
};

export default Keyboard;
