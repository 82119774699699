import React from 'react';
import { VideoPlayer } from '~/components/common/VideoPlayer';

export interface TheGamesScrollMobileProps {
  version?: string;
}

const dictionary = {
  default: {
    team: {
      title: `Team v Team Games`,
      desc: `Down for some friendly competition? Split up into teams and face-off in everything from wordy brain teasers to new twists on arcade classics.`,
    },
    competitive: {
      title: `Competitive Games`,
      desc: `Ramp up the rivalry and go solo as you battle it out with other players 1-on-1 to prove your gaming dominance.`,
    },
    icebreakers: {
      title: `Social Games`,
      desc: `Whether you want to get to know each other better or put your wits to the test, these games are all about raising the vibes and getting your entire crew cracking up in no time.
`,
    },
  },
  remote: {
    team: {
      title: `Team-building Games`,
      desc: `Nothing says bonding like frantically screaming at a computer screen together! These
            games allow you to divide your co-workers into teams and work together to solve
            everything from brain teasers to strategic head-scratchers.`,
    },
    competitive: {
      title: `Competitive Games`,
      desc: `Unleash your team's competitive side with head-to-head games that prioritize
            tearing the other team to shreds (all in good fun, of course 😉) over peaceful
            camaraderie.`,
    },
    icebreakers: {
      title: `Actually Fun Icebreakers`,
      desc: `Uncover a whole new level of bonding with icebreakers that break free from the boredom.
            Who knows? You might even discover that Judy, your quiet accountant, is secretly a black
            belt in karate!`,
    },
  },
};

const TheGamesScrollMobile: React.FC<TheGamesScrollMobileProps> = ({ version }) => {
  const optimization = 'g_center,c_fill,q_90,h_374,w_312';
  const previewImageOptimization = 'q_auto,f_auto,c_fill';

  const words = dictionary[version as keyof typeof dictionary] || dictionary.default;

  const gameTypes = [
    {
      title: words.team.title,
      desc: words.team.desc,
      videos: [
        '/v1697207149/b2b-landing-page-august-23/wordy-waterway-1',
        '/v1697207131/b2b-landing-page-august-23/word-hunt-3',
      ],
    },
    {
      title: words.competitive.title,
      desc: words.competitive.desc,
      videos: [
        '/v1697207099/b2b-landing-page-august-23/sumo-smash-2',
        '/v1697207081/b2b-landing-page-august-23/omega-pinch-highlight-2',
      ],
    },
    {
      title: words.icebreakers.title,
      desc: words.icebreakers.desc,
      videos: ['/v1697207061/b2b-landing-page-august-23/read-the-room-2'],
    },
  ];

  return (
    <div className='relative z-10 h-full w-full pt-2 md:pt-0'>
      <div className='flex flex-col md:items-center px-5 mt-[4.25rem]'>
        {gameTypes.map((game, index) => (
          <div key={`game-section-${index}`}>
            <div className='space-y-[1rem] ml-5 h-[20rem] flex items-start md:items-center justify-center flex-col'>
              <div className='flex items-baseline text-xl space-grotesk-regular text-white leading-normal pt-0.5 -mb-[0.25rem] font-bold'>
                <div className='text-coldsnap4 leading-tight'>0{index + 1}</div>
                <span className='pl-1'> / 03</span>
              </div>
              <div className='font-bold text-white space-grotesk-bold [text-shadow:_0_13.5px_24.75px_rgb(10_13_36_/_30%),_0_2.25px_7.5px_rgb(10_13_36_/_20%)] text-[2rem] md:text-5xl leading-[2.25rem] md:leading-18'>
                {game.title}
              </div>
              <div className='text-night1 space-grotesk-regular font-normal text-xl max-w-[23rem] md:max-w-[32rem] leading-tight'>
                {game.desc}
              </div>
            </div>
            <div className='flex md:justify-center gap-x-6 overflow-x-scroll touch-scroll -ml-5 mt-10 overflow-y-hidden w-screen px-10 scrol-mx-5 hide-scollbar relative'>
              {/* invisible div so scrolling works on iOS */}
              <div className='absolute h-full w-[150%] bg-transparent top-0 left-0 z-10' />
              {game.videos.map((video, videoIndex) => (
                <div
                  key={`video_${videoIndex}`}
                  className='relative flex-none w-[19.5rem] h-[23.4rem] rounded-2xl overflow-hidden pointer-events-none'
                >
                  <VideoPlayer
                    autoplay={true}
                    src={`https://res.cloudinary.com/prod-internet-game/video/upload/${optimization}${video}.mp4`}
                    thumbnail={`https://res.cloudinary.com/prod-internet-game/video/upload/${previewImageOptimization}${video}.jpg`}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TheGamesScrollMobile;
