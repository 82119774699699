import React, { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTailwindBreakpoints } from '~/hooks/ui/useTailwindBreakpoints';
import TheGamesScrollDesktop from './TheGamesScrollDesktop';
import TheGamesScrollMobile from './TheGamesScrollMobile';

interface TheGamesScrollProps {
  version?: string;
}

const TheGamesScroll: React.FC<TheGamesScrollProps> = ({ version }) => {
  const [showDesktopScroll, setShowDesktopScroll] = useState<boolean>(isDesktop);

  const { lg } = useTailwindBreakpoints();

  useEffect(() => {
    if (lg != showDesktopScroll) {
      setShowDesktopScroll(lg);
    }
  }, [lg, showDesktopScroll]);

  return showDesktopScroll ? (
    <TheGamesScrollDesktop version={version} />
  ) : (
    <TheGamesScrollMobile version={version} />
  );
};

export default TheGamesScroll;
