import React from 'react';
interface BaseProps {
  rounded?: string;
  zIndex?: string;
  opacity?: number;
  heatwaveType?: 'heatwave6' | 'heatwave7';
  gradient: { fromOpacity: number; toOpacity: number };
}

interface HoveredProps extends BaseProps {
  hovered: boolean;
  gradientHovered: { fromOpacity: number; toOpacity: number };
}

interface NotHoveredProps extends BaseProps {
  hovered?: boolean;
  gradientHovered?: never;
}

type HeatwaveGradientProps = HoveredProps | NotHoveredProps;

export const HeatwaveGradient: React.FC<HeatwaveGradientProps> = ({
  hovered,
  rounded = '',
  opacity = 100,
  zIndex,
  gradient,
  gradientHovered,
  heatwaveType = 'heatwave7',
}) => (
  <div
    className={`absolute inset-0 bg-gradient-to-b h-full w-full ${
      hovered && gradientHovered
        ? `from-${heatwaveType}/${gradientHovered.fromOpacity} to-${heatwaveType}/${gradientHovered.toOpacity}`
        : `from-${heatwaveType}/${gradient.fromOpacity}  to-${heatwaveType}/${gradient.toOpacity}`
    } ${rounded} ${zIndex && zIndex} pointer-events-none`}
    style={{ opacity }}
  />
);
